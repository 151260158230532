@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































.operate-dialog{
  width: auto !important;
  position: absolute !important;
  left: 10% !important;
  right: 10% !important;
  top: 10% !important;
  bottom: 10%;
  margin: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  .el-dialog__body{
    padding: 20px 30px !important;
    flex: 1 !important;
    overflow: auto !important;
  }
  .el-dialog__footer{
    .dialog-footer{
      display: flex !important;
      justify-content: center !important;
    }
  }
}
