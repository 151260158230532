@import "~@/erp/styles/variables/variables.scss";

















































.log-wrapper {
  padding: 5px 15px 15px 15px;
  .list-item {
    margin-bottom: 10px;
    line-height: 26px;
    padding-left: 5px;
  }
}
