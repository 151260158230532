@import "~@/erp/styles/variables/variables.scss";








































































































































































































































































































































































































































.ticket-management-edit{
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  .hearder-group{
    display: flex;
    justify-content: space-between;
    .hearder-group-tit{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-page-header{
        margin: 0;
      }
    }
  }
  .ticket-management-edit-cont{
    padding: 0 20px;
  }
  .file-box{
    padding:20px;
  }
  .write-off-results{
    p{
      padding: 20px 0 20px 0;
      font-size: 18px;
      color: #333;
    }
  }
}
